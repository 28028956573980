import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

const ModalNovo = ({
  isOpen,
  toggle,
  loading,
  newDomain,
  setNewDomain,
  subUsers,
  setSubUsers,
  handleAddDomain,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={"static"} centered>
      <ModalHeader toggle={toggle}>Adicionar Domínio</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleAddDomain}>
          <FormGroup>
            <Label>Domínio</Label>
            <Input
              type="text"
              placeholder="Digite o domínio (ex: example.com)"
              value={newDomain}
              onChange={(e) => setNewDomain(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup check>
            <Input
              type="checkbox"
              id="subUsersCheck"
              checked={subUsers}
              onChange={(e) => setSubUsers(e.target.checked)}
            />
            <Label check for="subUsersCheck">
              Permitir seus sub revendas a usarem?
            </Label>
          </FormGroup>
          <div className="text-end mt-3">
            <Button color="primary" type="submit" disabled={loading}>
              Adicionar
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
