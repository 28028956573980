import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Spinner,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";
import InputMask from "react-input-mask";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import FeatherIcon from "feather-icons-react";

const apiClient = new APIClient();

const ModalNovo = ({ modalNovo, setModalNovo, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [novoRevendedor, setNovoRevendedor] = useState(null);
  const [nome, setNome] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [senhaConfirmacao, setSenhaConfirmacao] = useState("");
  const [telefone, setTelefone] = useState("");
  const [limiteTestes, setLimiteTestes] = useState("0");
  const [limiteRevendas, setLimiteRevendas] = useState("0");
  const [valorCobrado, setValorCobrado] = useState(0);
  const [creditos, setCreditos] = useState("5");
  const [observacao, setObservacao] = useState("");

  const filtraUsername = (input) => {
    const resultado = input.replace(/[^A-Za-z0-9_]/g, "");
    return resultado.slice(0, 15);
  };

  const toggle = () => {
    setNome("");
    setUsername("");
    setEmail("");
    setSenha("");
    setSenhaConfirmacao("");
    setTelefone("");
    setLimiteTestes(0);
    setLimiteRevendas(0);
    setValorCobrado(0);
    setCreditos(0);
    setObservacao("");

    setModalNovo(!modalNovo);
  };

  const formatEmail = (event) => {
    let value = event.target.value;
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }

    setEmail(value);
  };

  const criar = async () => {
    setLoading(true);

    const data = {
      nome,
      username,
      email,
      password: senha,
      password_confirmation: senhaConfirmacao,
      telefone,
      limite_teste: limiteTestes === "" ? 0 : parseInt(limiteTestes),
      limite_usuarios: limiteRevendas === "" ? 0 : parseInt(limiteRevendas),
      valor_cobrado: valorCobrado,
      creditos: creditos === "" ? 0 : parseInt(creditos),
      observacao,
    };

    const response = await apiClient.post("/revendedores", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setNovoRevendedor(data);
      setShowForm(false);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Mensagem copiada com sucesso.");
  };

  const getMessage = () => {
    return `Olá! Aqui estão seus dados de acesso ao painel:

Login: ${username}
Senha: ${senha}
Painel: https://darkstore.fun

Você possui ${creditos} créditos disponíveis.

Qualquer dúvida estamos à disposição!`;
  };

  return (
    <Modal
      isOpen={modalNovo}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        {showForm ? "Novo Revendedor" : "Dados de Acesso do Revendedor"}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        {showForm ? (
          <div className="mt-4">
            <Row className="mt-3">
              <Col md="6">
                <div>
                  <Label htmlFor="nome" className="form-label">
                    Nome <span className="text-danger">*</span>
                  </Label>
                  <Input
                    id="nome"
                    className="form-control"
                    type="text"
                    placeholder="Nome"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    maxLength={255}
                  />
                </div>
              </Col>
              <Col md="6">
                <div>
                  <Label htmlFor="username" className="form-label">
                    Usuário <span className="text-danger">*</span>
                  </Label>
                  <Input
                    id="username"
                    className="form-control"
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) =>
                      setUsername(filtraUsername(e.target.value))
                    }
                  />
                  <small className="text-muted">
                    De 8 a 15 caracteres, apenas letras e números
                  </small>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="6">
                <div>
                  <Label htmlFor="email" className="form-label">
                    E-mail
                  </Label>
                  <Input
                    id="email"
                    className="form-control"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => formatEmail(e)}
                    maxLength={100}
                  />
                </div>
              </Col>
              <Col md="6">
                <div>
                  <Label htmlFor="telefone" className="form-label">
                    Telefone
                  </Label>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder="(11) 99999-9999"
                    defaultValue={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  >
                    {(inputProps) => (
                      <Input
                        type="text"
                        className="form-control"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="6">
                <div>
                  <Label htmlFor="senha" className="form-label">
                    Senha <span className="text-danger">*</span>
                  </Label>
                  <Input
                    id="senha"
                    className="form-control"
                    type="text"
                    placeholder="Digite a Senha"
                    value={senha}
                    onChange={(e) => {
                      setSenha(e.target.value);
                      setSenhaConfirmacao(e.target.value);
                    }}
                    maxLength={50}
                  />
                  <small className="text-muted">
                    Mínimo de 8 caracteres, apenas letras e números
                  </small>
                </div>
              </Col>
              <Col md="6">
                <div>
                  <Label htmlFor="limiteTestes" className="form-label">
                    Limite de Testes
                  </Label>
                  <Input
                    id="limiteTestes"
                    className="form-control"
                    type="text"
                    placeholder="Limite de Testes"
                    value={limiteTestes}
                    onChange={(e) =>
                      setLimiteTestes(e.target.value.replace(/\D/g, ""))
                    }
                    maxLength={4}
                  />
                  <small className="text-muted">Deixe 0 para ilimitado</small>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="6">
                <div>
                  <Label htmlFor="limiteRevendas" className="form-label">
                    Limite de Revendas
                  </Label>
                  <Input
                    id="limiteRevendas"
                    className="form-control"
                    type="text"
                    placeholder="Limite de Revendas"
                    value={limiteRevendas}
                    onChange={(e) =>
                      setLimiteRevendas(e.target.value.replace(/\D/g, ""))
                    }
                    maxLength={4}
                  />
                  <small className="text-muted">Deixe 0 para ilimitado</small>
                </div>
              </Col>
              <Col md="6">
                <div>
                  <Label htmlFor="valorCobrado" className="form-label">
                    Valor Cobrado <span className="text-danger">*</span>
                  </Label>
                  <NumericFormat
                    className="form-control"
                    placeholder="0,00"
                    value={valorCobrado}
                    onValueChange={({ value }) =>
                      setValorCobrado(parseFloat(value))
                    }
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    prefix="R$ "
                    fixedDecimalScale={true}
                    allowNegative={false}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="6">
                <div>
                  <Label htmlFor="creditos" className="form-label">
                    Créditos <span className="text-danger">*</span>
                  </Label>
                  <Input
                    id="creditos"
                    className="form-control"
                    type="text"
                    placeholder="Créditos"
                    value={creditos}
                    onChange={(e) =>
                      setCreditos(e.target.value.replace(/\D/g, ""))
                    }
                    maxLength={8}
                  />
                  <small className="text-muted">
                    Mínimo de 5 crédito para novo revendedor.
                  </small>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="12">
                <div>
                  <Label htmlFor="observacao" className="form-label">
                    Observação
                  </Label>
                  <Input
                    id="observacao"
                    className="form-control"
                    type="textarea"
                    placeholder="Observação"
                    value={observacao}
                    onChange={(e) => setObservacao(e.target.value)}
                    maxLength={255}
                  />
                </div>
              </Col>
            </Row>
            <div className="hstack gap-2 justify-content-end mt-5">
              <Link
                to="#"
                className="btn btn-link link-secondary fw-medium"
                onClick={() => toggle()}
                disabled={loading}
              >
                <i className="ri-close-line me-1 align-middle"></i> Fechar
              </Link>
              <Link
                to="#"
                className="btn btn-secondary"
                onClick={() => criar()}
                disabled={loading}
              >
                Criar
              </Link>
            </div>
          </div>
        ) : (
          <>
            <Alert color="info" className="mb-4">
              <strong>Revendedor criado com sucesso!</strong>
              <p className="mb-0">
                Copie a mensagem abaixo e envie para seu subrevendedor. Ela contém todos os dados necessários 
                para que ele acesse o painel.
              </p>
            </Alert>

            <div className="border rounded p-3 position-relative">
              <div 
                className="position-absolute top-0 end-0 m-2 cursor-pointer"
                onClick={() => handleCopy(getMessage())}
                style={{ cursor: "pointer", zIndex: 1 }}
              >
                <FeatherIcon icon="copy" size={18} />
              </div>
              <SyntaxHighlighter
                language="plaintext"
                style={docco}
                customStyle={{
                  background: "transparent",
                  padding: 0,
                  margin: 0,
                }}
                wrapLongLines={true}
              >
                {getMessage()}
              </SyntaxHighlighter>
            </div>

            <Row className="mt-4">
              <Col>
                <div className="text-end">
                  <Link
                    to="#"
                    className="btn btn-link link-secondary fw-medium"
                    onClick={toggle}
                  >
                    <i className="ri-close-line me-1 align-middle"></i> Fechar
                  </Link>
                </div>
              </Col>
            </Row>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
