import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import PropTypes from "prop-types";

const DeleteModal = ({ 
  show, 
  onDeleteClick, 
  onCloseClick, 
  deleteMessage, 
  deleteButtonText 
}) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered>
      <ModalBody className="py-3 px-5 text-center">
        <div className="mt-2">
          <h4 className="mb-3">Confirmação de Exclusão</h4>
          <p className="text-muted mb-4">
            {deleteMessage || "Tem certeza que deseja excluir este item?"}
          </p>
          <div className="hstack gap-2 justify-content-center">
            <Button 
              color="light" 
              onClick={onCloseClick}
            >
              Cancelar
            </Button>
            <Button 
              color="danger" 
              onClick={onDeleteClick}
            >
              {deleteButtonText || "Excluir"}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

DeleteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  deleteMessage: PropTypes.string,
  deleteButtonText: PropTypes.string
};

export default DeleteModal;
