import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Progress,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";

// Import images
import step1Image from "../../assets/images/modals/dominios/001.jpg";
import step2Image from "../../assets/images/modals/dominios/002.jpg";
import step3Image from "../../assets/images/modals/dominios/003.jpg";
import step4Image from "../../assets/images/modals/dominios/004.jpg";

const DomainWizardModal = ({ isOpen, toggle }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isImageFullscreen, setIsImageFullscreen] = useState(false);

  const steps = [
    {
      title: "Nova Ferramenta - Dominio Proprio",
      content: "Para adicionar um novo dominio basta seguir os passos abaixo:\n\nClique em Utilitários - Dominios",
      image: step1Image,
    },
    {
      title: "Adicionar Dominio",
      content: "Apos isso clique em Adicionar Dominio",
      image: step2Image,
    },
    {
      title: "Inserir Dominio",
      content: "Em seguida coloque seu dominio comprado, seja na hostinger, hostgator, godaddy ou de qualquer outro local.\n\nO Dominio precisa ser novo e válido.",
      image: step3Image,
    },
    {
      title: "Apontar Dominio",
      content: "Apos adicionar o dominio irá aparecer para onde deve ser apontado.\n\nAponte o Dominio para as respectivas NS diretamente no painel onde voce adquiriu seu dominio proprio.\n\nApos adicionar voce deverá aguardar de 12 a 24 horas para propagação.",
      image: step4Image,
    },
  ];

  const nextStep = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const ImageFullscreenModal = () => (
    <div
      className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        zIndex: 9999,
      }}
      onClick={() => setIsImageFullscreen(false)}
    >
      <div className="position-relative" style={{ maxWidth: "90%", maxHeight: "90vh" }}>
        <button
          className="position-absolute top-0 end-0 btn btn-link text-white p-2"
          onClick={() => setIsImageFullscreen(false)}
          style={{ zIndex: 10000 }}
        >
          <FeatherIcon icon="x" size={24} />
        </button>
        <img
          src={steps[currentStep - 1].image}
          alt={`Step ${currentStep}`}
          className="img-fluid"
          style={{ maxHeight: "90vh" }}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          {steps[currentStep - 1].title}
        </ModalHeader>
        <ModalBody className="position-relative">
          {currentStep > 1 && (
            <div 
              className="position-absolute start-0 top-50 translate-middle-y ms-2" 
              style={{ cursor: "pointer", zIndex: 1 }}
              onClick={prevStep}
            >
              <FeatherIcon icon="chevron-left" size={28} />
            </div>
          )}
          
          {currentStep < steps.length && (
            <div 
              className="position-absolute end-0 top-50 translate-middle-y me-2" 
              style={{ cursor: "pointer", zIndex: 1 }}
              onClick={nextStep}
            >
              <FeatherIcon icon="chevron-right" size={28} />
            </div>
          )}

          <div className="text-center mb-4">
            <Progress
              value={(currentStep / steps.length) * 100}
              className="mb-3"
            />
            <small>
              Passo {currentStep} de {steps.length}
            </small>
          </div>
          <div className="text-center mb-4">
            <img
              src={steps[currentStep - 1].image}
              alt={`Step ${currentStep}`}
              className="img-fluid rounded"
              style={{ maxHeight: "300px", cursor: "pointer" }}
              onClick={() => setIsImageFullscreen(true)}
            />
          </div>
          <div className="mt-3">
            {steps[currentStep - 1].content.split("\n").map((line, index) => (
              <p key={index} className="mb-2">
                {line}
              </p>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          {currentStep === steps.length && (
            <Button color="success" onClick={toggle}>
              Concluir
            </Button>
          )}
        </ModalFooter>
      </Modal>

      {isImageFullscreen && <ImageFullscreenModal />}
    </>
  );
};

export default DomainWizardModal;
