import React, { useState, useEffect, useCallback } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import DownloadColuna1 from "./DownloadColuna1";
import DownloadColuna2 from "./DownloadColuna2";
import { Link } from "react-router-dom";

const apiClient = new APIClient();

const ModalDownload = ({ modalDownload, setModalDownload, teste }) => {
  const [loading, setLoading] = useState(false);
  const [urlOriginal, setUrlOriginal] = useState("");
  const [urlEpg, setUrlEpg] = useState("");
  const [urlEncurtada, setUrlEncurtada] = useState("");
  const [urlEncurtadaEpg, setUrlEncurtadaEpg] = useState("");
  const [tipo, setTipo] = useState("m3u8");

  const [dominios, setDominios] = useState([]);
  const [dominio, setDominio] = useState("");

  const toggle = () => {
    setUrlOriginal("");
    setUrlEpg("");
    setUrlEncurtada("");
    setUrlEncurtadaEpg("");
    setDominio("");
    setDominios([]);
    setModalDownload(!modalDownload);
  };

  const alterarTipo = (tipo, teste, dom) => {
    if (tipo === "m3u8") {
      setUrlOriginal(
        `${dom}get.php?username=${teste?.externo_login}&password=${teste?.externo_senha}&type=m3u_plus&output=m3u8`
      );
      setUrlEpg(
        `${dom}xmltv.php?username=${teste?.externo_login}&password=${teste?.externo_senha}&type=m3u_plus&output=m3u8`
      );
    } else if (tipo === "ts") {
      setUrlOriginal(
        `${dom}get.php?username=${teste?.externo_login}&password=${teste?.externo_senha}&type=m3u_plus&output=ts`
      );
      setUrlEpg(
        `${dom}xmltv.php?username=${teste?.externo_login}&password=${teste?.externo_senha}&type=m3u_plus&output=ts`
      );
    } else if (tipo === "ssiptv-m3u8") {
      setUrlOriginal(
        `${dominio}ssiptv.php?username=${teste?.externo_login}&password=${teste?.externo_senha}&output=m3u8`
      );
      setUrlEpg(
        `${dominio}xmltv.php?username=${teste?.externo_login}&password=${teste?.externo_senha}&type=m3u_plus&output=m3u8`
      );
    } else if (tipo === "ssiptv-ts") {
      setUrlOriginal(
        `${dominio}ssiptv.php?username=${teste?.externo_login}&password=${teste?.externo_senha}&output=ts`
      );
      setUrlEpg(
        `${dominio}xmltv.php?username=${teste?.externo_login}&password=${teste?.externo_senha}&type=m3u_plus&output=ts`
      );
    }
    setTipo(tipo);
  };

  const fetchData = useCallback(async (teste) => {
    setLoading(true);
    if (dominios.length > 0 && dominio !== "") {
      setUrlOriginal(
        `${dominio}get.php?username=${teste?.externo_login}&password=${teste?.externo_senha}&type=m3u_plus&output=m3u8`
      );
      setUrlEpg(
        `${dominio}xmltv.php?username=${teste?.externo_login}&password=${teste?.externo_senha}&type=m3u_plus&output=m3u8`
      );
      setLoading(false);
      return;
    }

    const response = await apiClient.get(`/url-lista`);
    if (response.sucesso) {
      setDominios(response.dados);
      if (response.dados.length > 0) {
        const dom = response.dados[0];
        setDominio(dom.dominio);
        alterarTipo("m3u8", teste, dom.dominio);
      }
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  }, []);

  function validarURL(string) {
    const padrao = new RegExp(
      "^(https?:\\/\\/)?" + // protocolo
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // nome de domínio
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OU endereço ip (v4)
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // porta e caminho
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!padrao.test(string);
  }

  const encurtar = async (tipo) => {
    setLoading(true);
    if (!validarURL(urlOriginal)) {
      toast.error("URL inválida para encurtar.");
      setLoading(false);
      return;
    }

    const data = {
      url_original: tipo === "url" ? urlOriginal : urlEpg,
    };

    const response = await apiClient.post("/clientes/encurtar-url", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      if (tipo === "url") setUrlEncurtada(response.dados);
      else setUrlEncurtadaEpg(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchDominios = async (teste) => {
      await fetchData(teste);
    };
    if (modalDownload && teste) {
      fetchDominios(teste);
    }
  }, [modalDownload, teste]);

  return (
    <Modal
      isOpen={modalDownload}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      centered
      size="xl"
      className="modal-download"
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        URL do usuário {teste?.username}
      </ModalHeader>
      <ModalBody className="text-left p-4">
        <Row>
          {teste?.painel_tipo !== 0 && (
            <Col lg="6" xs="12" className="mb-3 mb-lg-0">
              <DownloadColuna1
                loading={loading}
                dominios={dominios}
                dominio={dominio}
                setDominio={setDominio}
                teste={teste}
                setUrlOriginal={setUrlOriginal}
                setUrlEpg={setUrlEpg}
                tipo={tipo}
                alterarTipo={alterarTipo}
                urlOriginal={urlOriginal}
                urlEpg={urlEpg}
                urlEncurtada={urlEncurtada}
                urlEncurtadaEpg={urlEncurtadaEpg}
                encurtar={encurtar}
              />
            </Col>
          )}
          <Col lg={teste?.painel_tipo !== 0 ? "6" : "12"} xs="12">
            <DownloadColuna2
              teste={teste}
              urlOriginal={urlOriginal}
              urlEpg={urlEpg}
              urlEncurtada={urlEncurtada}
              urlEncurtadaEpg={urlEncurtadaEpg}
            />
          </Col>
        </Row>
        <div className="hstack gap-2 justify-content-center mt-5">
          <Link
            to="#"
            className={`btn btn-link link-danger fw-medium`}
            onClick={() => toggle()}
          >
            <i className="ri-close-line me-1 align-middle"></i> Fechar
          </Link>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalDownload;
