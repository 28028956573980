import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalApagarMassa = ({ modalApagarMassa, setModalApagarMassa, setRefresh, selectedIds, setSelectedIds }) => {
  const [loading, setLoading] = useState(false);

  const apagarMassa = async () => {
    setLoading(true);
  
    const response = await apiClient.post(`/testes/massa/deletar`, {
      ids: selectedIds
    });

    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setSelectedIds([]);
      setRefresh(true);
      setModalApagarMassa(!modalApagarMassa);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  
    setLoading(false);
  };

  return (
    <Modal
      isOpen={modalApagarMassa}
      toggle={() => {
        setModalApagarMassa(!modalApagarMassa);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalApagarMassa(!modalApagarMassa);
        }}
      >
        Apagar {selectedIds.length} testes selecionados
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <h3>Tem certeza disso?</h3>
                <p id="mensagem">
                  Os <b>{selectedIds.length} testes selecionados</b> serão{" "}
                  <b className={`text-danger`}>completamente apagados</b> do
                  painel original.
                </p>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className={`btn btn-link link-danger fw-medium`}
              onClick={() => setModalApagarMassa(!modalApagarMassa)}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className={`btn btn-danger`}
              onClick={apagarMassa}
              disabled={loading}
            >
              Apagar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalApagarMassa;
