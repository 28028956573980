import React, { useState, useEffect, useCallback } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Table,
  Badge,
} from "reactstrap";

import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import DeleteModal from "./DeleteModal";
import DomainWizardModal from "./DomainWizardModal";
import ModalNovo from "./ModalNovo";
import ModalEditar from "./ModalEditar";

const apiClient = new APIClient();

// Domain validation function
const isValidDomain = (cleanDomain) => {
  // Regex for domain validation
  // Allows:
  // - Lowercase and uppercase letters
  // - Numbers
  // - Hyphens (not at start or end)
  // - Multiple subdomains
  // - Any top-level domains
  const domainRegex = /^([a-zA-Z0-9][\-a-zA-Z0-9]*\.)+[\-a-zA-Z0-9]{2,20}$/;

  return domainRegex.test(cleanDomain);
};

const DomainUsers = ({ userDados }) => {
  const [domains, setDomains] = useState([]);
  const [modalAddDomain, setModalAddDomain] = useState(false);
  const [newDomain, setNewDomain] = useState("");
  const [subUsers, setSubUsers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingRenew, setLoadingRenew] = useState(false);

  // Delete confirmation modal state
  const [deleteModal, setDeleteModal] = useState(false);
  const [domainToDelete, setDomainToDelete] = useState(null);
  const [wizardModal, setWizardModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);

  // CSS for rotating animation
  const rotateAnimation = {
    animation: "rotate 1s linear infinite",
  };

  const rotateKeyframes = `
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  `;

  useEffect(() => {
    // Add keyframes to document
    const styleSheet = document.createElement("style");
    styleSheet.textContent = rotateKeyframes;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  const fetchDomains = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.get("/domain-users");

    if (response.sucesso) {
      setDomains(response.dados || []);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") {
        toast.error(response.mensagem);
      }

      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await fetchDomains();
    };

    fetch();
  }, [fetchDomains]);

  const handleAddDomain = async (e) => {
    e.preventDefault();

    // Clean the domain first
    const trimmedDomain = newDomain
      .toLowerCase()
      .trim()
      .replace("http://", "")
      .replace("https://", "")
      .replace("/", "")
      .replace("www.", "");

    // Validate domain
    if (!isValidDomain(trimmedDomain)) {
      toast.error("Por favor, insira um domínio válido. Exemplo: example.com");
      return;
    }

    // Check if domain already exists
    if (domains.some((d) => d.dominio.toLowerCase() === trimmedDomain)) {
      toast.error("Este domínio já foi cadastrado");
      return;
    }

    setLoading(true);

    const response = await apiClient.post("/domain-users", {
      dominio: trimmedDomain,
      sub_users: subUsers,
    });

    if (response.sucesso) {
      toast.success("Domínio adicionado com sucesso");
      setNewDomain("");
      setModalAddDomain(false);
      await fetchDomains();
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem || "Erro ao adicionar domínio");
    }

    setLoading(false);
  };

  const handleRenewDomain = async (domainId) => {
    setLoadingRenew(true);

    const response = await apiClient.post(`/domain-users/${domainId}/refazer`);

    if (response.sucesso) {
      toast.success(response.mensagem);
      await fetchDomains();
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem || "Erro ao adicionar domínio");
    }

    setLoadingRenew(false);
  };

  const handleEditDomain = async (subUsers) => {
    if (!selectedDomain) return;

    setLoading(true);

    const response = await apiClient.put(`/domain-users/${selectedDomain.id}`, {
      sub_users: subUsers,
    });

    if (response.sucesso) {
      toast.success("Domínio atualizado com sucesso");
      await fetchDomains();
      setEditModal(false);
      setSelectedDomain(null);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem || "Erro ao atualizar domínio");
    }

    setLoading(false);
  };

  const confirmDeleteDomain = (domain) => {
    setDomainToDelete(domain);
    setDeleteModal(true);
  };

  const handleDeleteDomain = async () => {
    if (!domainToDelete) {
      toast.error("Por favor, selecione um domínio para excluir");
      return;
    }

    setLoading(true);

    const response = await apiClient.delete(
      `/domain-users/${domainToDelete.id}`
    );

    if (response.sucesso) {
      toast.success("Domínio removido com sucesso");
      await fetchDomains();
      setDeleteModal(false);
      setDomainToDelete(null);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem || "Erro ao remover domínio");
    }

    setLoading(false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("DNS copiado para área de transferência");
      })
      .catch((err) => {
        console.error("Erro ao copiar:", err);
        toast.error("Erro ao copiar DNS");
      });
  };

  const openEditModal = (domain) => {
    setSelectedDomain(domain);
    setEditModal(true);
  };

  return (
    <>
      <Card>
        <CardHeader className="d-flex justify-content-between align-items-center">
          <h4 className="card-title mb-0">Domínios</h4>
          <div>
            <Button
              color="info"
              className="me-2"
              onClick={() => setWizardModal(true)}
              title="Como adicionar um domínio?"
            >
              <FeatherIcon
                icon="help-circle"
                size={16}
                className="me-1 me-sm-1"
              />
              <span className="d-none d-sm-inline">Ajuda</span>
            </Button>

            <Button color="primary" onClick={() => setModalAddDomain(true)}>
              <FeatherIcon icon="plus" size={16} className="me-1 me-sm-1" />{" "}
              Adicionar Domínio
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Table responsive striped>
            <thead>
              <tr>
                <th>Domínio</th>
                <th>DNS 1</th>
                <th>DNS 2</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {domains.map((domain) => (
                <tr key={domain.id}>
                  <td>{domain.dominio}</td>
                  <td>
                    {domain.dns1}
                    <Button
                      color="link"
                      title="Copiar DNS"
                      className="p-1 ml-2 text-info"
                      onClick={() => copyToClipboard(domain.dns1)}
                    >
                      <FeatherIcon icon="copy" size="14" />
                    </Button>
                  </td>
                  <td>
                    {domain.dns2}{" "}
                    <Button
                      color="link"
                      title="Copiar DNS"
                      className="p-1 ml-2 text-info"
                      onClick={() => copyToClipboard(domain.dns2)}
                    >
                      <FeatherIcon icon="copy" size="14" />
                    </Button>
                  </td>
                  <td>
                    {domain.status === "ativo" && (
                      <Badge color="success">Ativo</Badge>
                    )}
                    {domain.status === "aguardando" && (
                      <Badge color="warning">Aguardando</Badge>
                    )}
                    {domain.status === "refazer" && (
                      <div className="d-flex align-items-center">
                        <Badge
                          color="info"
                          className="me-2 cursor-pointer"
                          onClick={() =>
                            !loadingRenew && handleRenewDomain(domain.id)
                          }
                        >
                          Refazer
                        </Badge>
                        <FeatherIcon
                          icon="refresh-cw"
                          size="14"
                          style={loadingRenew ? rotateAnimation : {}}
                          className="cursor-pointer"
                          onClick={() =>
                            !loadingRenew && handleRenewDomain(domain.id)
                          }
                        />
                      </div>
                    )}
                  </td>
                  <td>
                    <Link
                      to="#"
                      className="text-success"
                      title="Editar"
                      onClick={() => openEditModal(domain)}
                    >
                      <FeatherIcon icon="edit" width="16" className="ms-3" />
                    </Link>
                    <Link
                      to="#"
                      className="text-danger"
                      title="Apagar"
                      onClick={() => confirmDeleteDomain(domain)}
                    >
                      <FeatherIcon icon="trash" width="16" className="ms-3" />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {domains.length === 0 && (
            <div className="text-center text-muted">
              Nenhum domínio cadastrado
            </div>
          )}

          {domains.length > 0 && (
            <div className="mt-3 text-muted">
              <p>
                <strong>Importante:</strong> Para utilizar estes domínios, você
                precisará alterar as zonas de DNS no seu provedor de serviços de
                domínio. Copie os DNSs acima e configure-os no painel do seu
                provedor de hospedagem.
              </p>
              <p>
                <em>
                  Atenção: A propagação das alterações de DNS pode levar de 12 a
                  24 horas, dependendo do provedor de serviços. Durante este
                  período, seu domínio pode não estar completamente funcional.
                </em>
              </p>
            </div>
          )}
        </CardBody>
      </Card>

      <ModalNovo
        isOpen={modalAddDomain}
        toggle={() => setModalAddDomain(!modalAddDomain)}
        loading={loading}
        newDomain={newDomain}
        setNewDomain={setNewDomain}
        subUsers={subUsers}
        setSubUsers={setSubUsers}
        handleAddDomain={handleAddDomain}
      />

      <ModalEditar
        isOpen={editModal}
        toggle={() => setEditModal(!editModal)}
        loading={loading}
        domain={selectedDomain}
        onSave={handleEditDomain}
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteDomain}
        onCloseClick={() => setDeleteModal(false)}
      />

      <DomainWizardModal
        isOpen={wizardModal}
        toggle={() => setWizardModal(false)}
      />
    </>
  );
};

export default DomainUsers;
