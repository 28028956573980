import React, { Fragment, useState, useEffect } from "react";
import { Col, Container, Row, Alert } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import DomainUsers from "./DomainUsers";

const Dominios = () => {
  document.title = "Domínios Personalizados | Dark Store";
  const [userDados, setUserDados] = useState({});

  const getData = async () => {
    const dados = JSON.parse(localStorage.getItem("authUser"));
    setUserDados(dados.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Domínios Próprios" pageTitle="Utilitários" />
          <Alert
            color="warning"
            className="alert-additional mb-3"
          >
            <div className="alert-body">
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <i className="ri-information-line fs-16 align-middle me-2"></i>
                </div>
                <div className="flex-grow-1">
                  <h5 className="alert-heading">Antes de cadastrar um domínio:</h5>
                  <p className="mb-2">• Certifique-se de que o domínio já está registrado em um serviço de hospedagem.</p>
                  <p className="mb-0">• Domínios com extensão .com.br não são permitidos, pois são incompatíveis com o sistema.</p>
                </div>
              </div>
            </div>
          </Alert>
          <DomainUsers userDados={userDados} />
        </Container>
      </div>
    </Fragment>
  );
};

export default Dominios;
