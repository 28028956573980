import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalApagar = ({
  modalApagar,
  setModalApagar,
  setRefresh,
  revendedor,
}) => {
  const [loading, setLoading] = useState(false);
  const [motivo, setMotivo] = useState("");
  const [userDados, setUserDados] = useState({});

  const toggle = () => {
    setMotivo("");
    setModalApagar(!modalApagar);
  };

  const getUserDados = async () => {
    const dados = JSON.parse(localStorage.getItem("authUser"));
    setUserDados(dados.data);
  };

  const apagar = async (revendedor, motivo) => {
    setLoading(true);

    const data = { motivo };

    const response = await apiClient.delete(
      `/revendedores/${revendedor.id}`,
      {},
      data
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);toggle();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUserDados();
    };
    fetchData();
  }, []);

  return (
    <Modal
      isOpen={modalApagar}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Apagar {revendedor?.nome}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          {userDados?.id !== 1 ? (
            <>
              <Row>
                <div>
                  <Label htmlFor="motivo" className="form-label">
                    Motivo
                  </Label>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="motivo"
                    value={motivo}
                    onChange={(e) => setMotivo(e.target.value)}
                    maxLength={255}
                  />{" "}
                  <small className="text-muted">
                    Este formulário envia um pedido para apagar o revendedor. O
                    pedido será analisado e, se aprovado, o revendedor será
                    apagado.
                  </small>
                </div>
              </Row>
            </>
          ) : (
            <p>
              Ao prosseguir, você <b className="text-danger">apagará</b> o
              revendedor {revendedor?.nome}. Tem certeza disso?
            </p>
          )}

          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className="btn btn-link link-danger fw-medium"
              onClick={() => toggle()}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-danger"
              onClick={() => apagar(revendedor, motivo)}
              disabled={loading}
            >
              Apagar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalApagar;
