import React from "react";
import { Row, Col, Label, Input, Spinner, Alert } from "reactstrap";
import { toast } from "react-toastify";

const DownloadColuna1 = ({
  loading,
  dominios,
  dominio,
  setDominio,
  teste,
  setUrlOriginal,
  setUrlEpg,
  tipo,
  alterarTipo,
  urlOriginal,
  urlEpg,
  urlEncurtada,
  urlEncurtadaEpg,
  encurtar,
}) => {
  return (
    <div className="mt-4">
      <Row className="mt-3">
        <Col xs="12">
          <div>
            <Label htmlFor="dominio" className="form-label">
              URL da Lista
            </Label>
            <Input
              type="select"
              className="form-control"
              id="dominio"
              value={dominio}
              onChange={(e) => {
                setDominio(e.target.value);
                setUrlOriginal(
                  `${e.target.value}get.php?username=${teste?.externo_login}&password=${teste?.externo_senha}&type=m3u_plus&output=m3u8`
                );
                setUrlEpg(
                  `${e.target.value}xmltv.php?username=${teste?.externo_login}&password=${teste?.externo_senha}&type=m3u_plus&output=m3u8`
                );
              }}
              disabled={loading}
            >
              {dominios.map((dom) => (
                <option key={dom.id} value={dom.dominio}>
                  {dom.dominio}
                </option>
              ))}
            </Input>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12">
          <div>
            <Label htmlFor="tipo" className="form-label">
              Tipo de URL
            </Label>
            <div className="input-group flex-wrap">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tipo"
                  id="m3u8"
                  value="m3u8"
                  checked={tipo === "m3u8"}
                  onChange={() => {
                    alterarTipo("m3u8", teste, dominio);
                  }}
                />
                <label className="form-check-label" htmlFor="m3u8">
                  M3U8
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tipo"
                  id="ts"
                  value="ts"
                  checked={tipo === "ts"}
                  onChange={() => {
                    alterarTipo("ts", teste, dominio);
                  }}
                />
                <label className="form-check-label" htmlFor="ts">
                  TS
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tipo"
                  id="ssiptv-m3u8"
                  value="ssiptv-m3u8"
                  checked={tipo === "ssiptv-m3u8"}
                  onChange={() => {
                    alterarTipo("ssiptv-m3u8", teste, dominio);
                  }}
                />
                <label className="form-check-label" htmlFor="ssiptv-m3u8">
                  SSIPTV M3U8
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tipo"
                  id="ssiptv-ts"
                  value="ssiptv-ts"
                  checked={tipo === "ssiptv-ts"}
                  onChange={() => {
                    alterarTipo("ssiptv-ts", teste, dominio);
                  }}
                />
                <label className="form-check-label" htmlFor="ssiptv-ts">
                  SSIPTV TS
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col xs="12">
          <div>
            <Label htmlFor="url" className="form-label">
              URL Original
            </Label>
            <div className="input-group">
              <Input
                type="text"
                className="form-control"
                id="url"
                value={urlOriginal || "Carregando..."}
                onChange={() => {}}
                disabled
              />
              <button
                className="btn btn-success"
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(urlOriginal);
                  toast.success("URL copiada com sucesso.");
                }}
                disabled={!urlOriginal || loading}
              >
                Copiar
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12">
          <div>
            <Label htmlFor="urlEpg" className="form-label">
              URL EPG
            </Label>
            <div className="input-group">
              <Input
                type="text"
                className="form-control"
                id="urlEpg"
                value={urlEpg || "Carregando..."}
                onChange={() => {}}
                disabled
              />
              <button
                className="btn btn-success"
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(urlEpg);
                  toast.success("URL copiada com sucesso.");
                }}
                disabled={!urlEpg || loading}
              >
                Copiar
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12">
          <div>
            <Label htmlFor="urlEncurtada" className="form-label">
              URL Encurtada
            </Label>
            <div className="input-group">
              <Input
                type="text"
                className="form-control"
                id="urlEncurtada"
                value={urlEncurtada}
                onChange={() => {}}
                disabled
              />
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => encurtar("url")}
                disabled={loading || urlEncurtada || !urlOriginal}
              >
                {loading ? (
                  <Spinner style={{ width: "12px", height: "12px" }} />
                ) : (
                  "Encurtar"
                )}
              </button>
              <button
                className="btn btn-success"
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(urlEncurtada);
                  toast.success("URL copiada com sucesso.");
                }}
                disabled={!urlEncurtada || loading}
              >
                Copiar
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12">
          <div>
            <Label htmlFor="urlEncurtadaEpg" className="form-label">
              URL EPG Encurtada
            </Label>
            <div className="input-group">
              <Input
                type="text"
                className="form-control"
                id="urlEncurtadaEpg"
                value={urlEncurtadaEpg}
                onChange={() => {}}
                disabled
              />
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => encurtar("epg")}
                disabled={loading || urlEncurtadaEpg || !urlEpg}
              >
                {loading ? (
                  <Spinner style={{ width: "12px", height: "12px" }} />
                ) : (
                  "Encurtar"
                )}
              </button>
              <button
                className="btn btn-success"
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(urlEncurtadaEpg);
                  toast.success("URL copiada com sucesso.");
                }}
                disabled={!urlEncurtadaEpg || loading}
              >
                Copiar
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DownloadColuna1;
