import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap";

const ModalEditar = ({ isOpen, toggle, loading, domain, onSave }) => {
  const [subUsers, setSubUsers] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(subUsers);
  };

  useEffect(() => {
    setSubUsers(domain?.sub_users);
  }, [domain]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={"static"} centered>
      <ModalHeader toggle={toggle}>Editar Domínio</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Domínio</Label>
            <Input
              type="text"
              value={domain?.dominio || ""}
              readonly
              disabled
            />
          </FormGroup>
          <FormGroup>
            <Label>DNS 1</Label>
            <Input type="text" value={domain?.dns1 || ""} readonly disabled />
          </FormGroup>
          <FormGroup>
            <Label>DNS 2</Label>
            <Input type="text" value={domain?.dns2 || ""} readonly disabled />
          </FormGroup>
          <FormGroup>
            <Label>Status</Label>
            <div>
              {domain?.status === "ativo" && (
                <Badge color="success">Ativo</Badge>
              )}
              {domain?.status === "aguardando" && (
                <Badge color="warning">Aguardando</Badge>
              )}
              {domain?.status === "refazer" && (
                <Badge color="info">Refazer</Badge>
              )}
            </div>
          </FormGroup>
          <FormGroup check className="mt-3">
            <Input
              type="checkbox"
              id="subUsersEditCheck"
              checked={subUsers}
              onChange={(e) => setSubUsers(e.target.checked)}
            />
            <Label check for="subUsersEditCheck">
              Permitir seus sub revendas a usarem?
            </Label>
          </FormGroup>
          <div className="text-end mt-3">
            <Button color="primary" type="submit" disabled={loading}>
              Salvar
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
